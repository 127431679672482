/**
Colors
 */
// palette
$c_black: #000;
$c_white: #FFF;
$c_alizarin_crimson: #DC1C2E;
$c_tall_poppy: #B22B2E;
$c_cobalt: #003DA5;
$c_biscay: #1A3668;
$c_sail: #A4D7F4;
$c_ship_gray: #404041;
$c_heavy_gray: #232323;
$c_mid_gray: #626366;
$c_light_gray: #F4F4F4;
$c_regent_gray: #949CA1;
$c_silver_sand: #C4C6C8;
$c_humming_bird: #E3F3FB;
// accents
$c_coral_red: #FF3D48;
$c_blue_ribbon: #095FF0;
$c_lochmara: #007DC3;

// Zeplin names:
$c_navy: #1a3668;
$c_remax_blue: #003da5;
$c_light_gray: #c4c6c8;
$c_medium_gray: #838587;
$c_text_gray: #404041;
$c_medium_blue: #007dc3;
$c_light_blue: #a4d7f4;
$c_remax_red: #dc1c2e;
$c_accent_green: #64c887;
$c_off_white: #F4F4F4;

// hover
$c_accent_green_light: lighten($c_accent_green, 10%);

/**
Fonts
 */
@font-face {
  font-family: "BebasNeue-Regular";
  font-weight: normal;
  src: url("~@/assets/fonts/BebasNeue-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "BebasNeue-Regular";
  font-weight: bold;
  src: url("~@/assets/fonts/BebasNeue-Bold.woff2") format("woff2");
}

$f_bebas_neue: 'BebasNeue-Regular', sans-serif;

// declared in <head>
$f_montserrat: 'Montserrat', sans-serif;
$f_opensans: 'Open Sans', sans-serif;

/**
Animation timings
 */
$t_fast: 120ms ease;
$t_normal: 240ms ease;
$t_header: 350ms ease-out;

/**
Responsive breakpoints
 */
//widths
$sr_w_xs: 'max-width: 600px';
$sr_w_not_xs: 'min-width: 601px';
$sr_w_sm: 'max-width: 768px';
$sr_w_not_sm: 'min-width: 769px';
$sr_w_md: 'max-width: 1264px';
$sr_w_not_md: 'min-width: 1265px';
$sr_w_lg: 'max-width: 1904px';
$sr_w_xl: 'min-width: 1905px';
// heights
// add height breakpoints here
// orientations
$sr_orientation_portrait: 'orientation: portrait';
$sr_orientation_landscape: 'orientation: landscape';

/**
Z-indices
 */
$z_index_back: -1;
$z_index_min: 1;
$z_index_top: 100;
$z_index_header: 2000;
$z_index_overlay: 4000;
$z_index_max: 9999;
/**
Mixins
 */

// reset UL elements
@mixin resetUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@mixin maxWidth {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1200px;
  margin: 0 auto;

  @include createQuery($sr_w_md) {
    flex-direction: column;
  }

  @include createQuery($sr_w_sm) {
    padding: 0 20px;
  }

  @include createQuery($sr_w_xs) {
    
    // max-width: 286px;
  }
}

@mixin inner {
  padding-left: 200px;
  padding-right: 242px;

  @include createQuery($sr_w_md) {
    max-width: min(calc(100% - 40px), 900px);
    padding-left: 10px;
    padding-right: 0;
  }
}

@mixin container {
  width: 286px;
  margin: 0 auto;
  padding: 40px 0;

  @include createQuery($sr_w_not_sm) {
    width: 684px;
    padding: 48px 0;
  }

  @include createQuery($sr_w_not_md) {
    width: 1200px;
  }
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Create media queries
// usage:
// @include createQuery($breakpoint) {}
// @include createQuery([$breakpoint_width, $breakpoint_height, ...]) {}
@mixin createQuery( $mediaQueries : '', $typesString : 'screen and' ) {

  $i: 1;
  $mediaQueryString: '';

  @if type-of($mediaQueries) == 'list' {
    @each $mediaQuery in $mediaQueries {

      @if type-of($mediaQuery) == 'list' {
        $mediaQueryString: $mediaQueryString + '(' + nth($mediaQuery, 1) + ') ' + nth($mediaQuery, 2);
      } @else if length($mediaQueries) > $i {
        $mediaQueryString: $mediaQueryString + ' (' + $mediaQuery + ') and';
      } @else {
        $mediaQueryString: $mediaQueryString + ' (' + $mediaQuery + ')';
      }

      $i: $i + 1;
    }
  } @else {
    $mediaQueryString: '(' + $mediaQueries + ')';
  }

  $mediaQueryString: $typesString + ' ' + $mediaQueryString;

  @media #{$mediaQueryString} {
    @content;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

// set placeholder styles
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Adds an animation to &
@mixin animation($animationParameters : '') {

  $animationName: #{&}__animation;
  $animationName: str-replace($animationName, '(', '');
  $animationName: str-replace($animationName, ')', '');
  $animationName: str-replace($animationName, ' > ', '_direct-child_');
  $animationName: str-replace($animationName, ' ~ ', '_right-after-first_');
  $animationName: str-replace($animationName, ' + ', '_after-first_');
  $animationName: str-replace($animationName, ' ', '_direct_');
  $animationName: str-replace($animationName, ':', '--');
  $animationName: str-replace($animationName, '~', '__');
  $animationName: str-replace($animationName, '.', '');

  animation: $animationName $animationParameters;

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin defaultCopyStyles() {
  font-size: 16px;
  line-height: 2.06;
  color: $c_ship_gray;

  p {
    margin: 1em 0;
  }

  p + p {
    margin-top: 1.5em;
  }

  a {
    // color: $c_alizarin_crimson;
    // text-decoration: underline;
    // text-decoration-color: transparent;
    transition: color $t_normal;
    text-decoration: underline;
    font-weight: bold;
    color: $c_text_gray;

    &:hover {
      color: $c_remax_blue;
    }
  }

  @include createQuery($sr_w_xs) {
    font-size: 13px;
    line-height: 1.85;
  }
}


@mixin printMaxWith {
  @media print {
    padding-left: 59px;
    padding-right: 50px;
  }
}

@mixin hideInPrint {
  @media print {
    display: none;
  }
}

@mixin noAnimationInPrint {
  @media print {
    opacity: 1 !important;
  }
}
@mixin opensans12 {
    font-family: $f_opensans;
    font-size: 12px;
    line-height: 18px;
}
@mixin opensans12-12 {
    font-family: $f_opensans;
    font-size: 12px;
    line-height: 12px;
}

@mixin opensansSemi8 {
    font-family: $f_opensans;
    font-size: 8px;
    line-height: 11px;
    letter-spacing: 1.7px;
    font-weight: 600;
    text-transform: uppercase;
}

@mixin montserratSemi14 {
    font-family: $f_montserrat;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.44px;
    font-weight: 600;
}
@mixin montserratSemi19 {
    font-family: $f_montserrat;
    font-size: 19px;
    line-height: 31px;
    letter-spacing: 0.59px;
    font-weight: 600;
}
@mixin montserratSemi23 {
    font-family: $f_montserrat;
    font-size: 23px;
    line-height: 31px;
    letter-spacing: 0.72px;
    font-weight: 600;
}
@mixin montserratSemi32 {
    font-family: $f_montserrat;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.72px;
    font-weight: 600;
}

@mixin montserratBlack14 {
    font-family: $f_montserrat;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.58px;
    font-weight: 900;
}






















































































































































.timeline-hero {
  $root: &;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgba(164, 215, 244, 0.36),
    #ffffff 77%
  );
  background-color: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_not_xs) {
    min-height: 730px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  &__title-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    z-index: $z_index_min;

    #{$root}__lines-veritical {
      position: absolute;
      top: 0;
      width: 2px;
      height: 30%;
      background-color: $c_alizarin_crimson;

      opacity: 0;
    }

    #{$root}__lines-bottom {
      position: absolute;
      bottom: 0;
      left: calc(50% - 1px);
      height: 20%;

      z-index: $z_index_back;

      #{$root}__lines-bottom-horz {
        width: 1000px;
        height: 2px;
        background-color: $c_alizarin_crimson;

        opacity: 0;
      }

      #{$root}__lines-bottom-vert {
        height: 100%;
        width: 2px;
        background-color: $c_alizarin_crimson;

        opacity: 0;
      }
    }

    #{$root}__title {
      margin: 28px auto;
      font-size: 58px;
      line-height: normal;
      text-align: center;
      text-transform: uppercase;
      color: $c_ship_gray;

      opacity: 0; // set in js

      @include createQuery($sr_w_md) {
        margin-bottom: 13px;
        font-size: 42px;
      }

      @include createQuery($sr_w_xs) {
        font-size: 38px;
      }
    }
  }

  &__images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z_index_min;

    #cloud-2 {
      position: absolute;
      left: 200px;
      top: 122px;

      @include createQuery($sr_w_xs) {
        left: 44px;
        top: 60px;
        width: 85px;
      }
    }

    #cloud-1 {
      position: absolute;
      left: -80px;
      bottom: 105px;

      @include createQuery($sr_w_xs) {
        left: 10px;
        bottom: 109px;
        width: 192px;
      }
    }

    #cloud-3 {
      position: absolute;
      right: -20px;
      bottom: 218px;

      @include createQuery($sr_w_xs) {
        right: 0px;
        bottom: 161px;
        width: 108px;
      }
    }

    #remax-balloon {
      position: absolute;
      left: 246px;
      bottom: 218px;

      @include createQuery($sr_w_xs) {
        left: 122px;
        bottom: 148px;
        width: 65px;
      }
    }
  }

  &__next {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 83px;
    text-align: center;
    z-index: $z_index_top;

    @include createQuery($sr_w_xs) {
      margin-bottom: 27px;
    }

    #{$root}__next-cta {
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke-dashoffset: 0;
            animation: mouse-scroll-btn-roll-over .55s cubic-bezier(.5,.1,.07,1);
          }
        }
      }

      svg {
        animation:nudgeMouse 2.4s cubic-bezier(0.250,0.460,0.450,0.940) infinite;

        path {
          stroke-dashoffset: 0;
          animation:mouse-scroll-btn-roll-out .55s cubic-bezier(.5,.1,.07,1);
        }
      }
    }
  }
}

@keyframes mouse-scroll-btn-roll-over{
  0%{stroke-dashoffset:120}
  100%{stroke-dashoffset:0}
}

@keyframes mouse-scroll-btn-roll-out{
  0%{stroke-dashoffset:0}
  100%{stroke-dashoffset:-120}
}
@keyframes nudgeMouse{
  0%{
    -webkit-transform:translateY(0);
    transform:translateY(0);
  }
  45%{
    -webkit-transform:translateY(8px);
    transform:translateY(8px);
  }
  65%,100%{
    -webkit-transform:translateY(0);
    transform:translateY(0);
  }
}
